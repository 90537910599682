import React from "react"
import "../styles.css"
const Table = ({ className }) => {
  return (
    <div className="container ">
      <h2 className="table-title text-uppercase text-white mt-2">CORROSION</h2>
      <p className="text-white">
        With inherently low metal corrosion properties, Enviro‐Syn HCR‐7000 has
        corrosion rates well below traditional HCl acid systems on typical grade
        steels.
      </p>
      <div>
        <p className="text-md-center text-white mt-5">
          <p>Corrosion rates of Enviro‐Syn HCR‐7000 blends.</p>
        </p>
      </div>
      <table className="table text-white table-rsponsive" id="card">
        <thead>
          <tr>
            <th scope="col">Blend</th>
            <th scope="col">Temp (°C /°F) </th>
            <th scope="col">Coupon</th>
            <th scope="col">Time (hr) </th>
            <th scope="col">Corrosion (mm/yr)</th>
            <th scope="col">Corrosion (lb/ft²)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">90 / 195</td>
            <td data-label="Coupon">L‐80</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">6.255</td>
            <td data-label="Corrosion (lb/ft²)">0.007</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">90 / 195</td>
            <td data-label="Coupon">N‐80</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">6.015</td>
            <td data-label="Corrosion (lb/ft²)">0.007</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">90 / 195</td>
            <td data-label="Coupon">J‐55</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">3.465</td>
            <td data-label="Corrosion (lb/ft²)">0.005</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">90 / 195</td>
            <td data-label="Coupon">P‐110</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">11.792</td>
            <td data-label="Corrosion (lb/ft²)">0.013</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">110 / 230</td>
            <td data-label="Coupon">QT‐900</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">8.520</td>
            <td data-label="Corrosion (lb/ft²)">0.009</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">90 / 195</td>
            <td data-label="Coupon">1018CS</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">6.531</td>
            <td data-label="Corrosion (lb/ft²)">0.007</td>
          </tr>
          <tr>
            <td data-label="Blend">33%</td>
            <td data-label="Temp (°C /°F)">120 / 250</td>
            <td data-label="Coupon">L‐80</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">17.906</td>
            <td data-label="Corrosion (lb/ft²)">0.020</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">120 / 250</td>
            <td data-label="Coupon">P‐110</td>
            <td data-label="Time (hr)">6</td>
            <td data-label="Corrosion (mm/yr)">27.880</td>
            <td data-label="Corrosion (lb/ft²)">0.031</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">150 / 300</td>
            <td data-label="Coupon">L-80</td>
            <td data-label="Time (hr)">4</td>
            <td data-label="Corrosion (mm/yr)">37.183</td>
            <td data-label="Corrosion (lb/ft²)">0.027</td>
          </tr>
          <tr>
            <td data-label="Blend">50%</td>
            <td data-label="Temp (°C /°F)">180 / 355</td>
            <td data-label="Coupon">L-80</td>
            <td data-label="Time (hr)">2</td>
            <td data-label="Corrosion (mm/yr)">12.282</td>
            <td data-label="Corrosion (lb/ft²)">0.004</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Table
