import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"
const Solutions = ({ props }) => {
  return (
    <div className="row mt-5 py-lg-5">
      <div className="d-flex flex-column col-md-6 ">
        <div
          className="Solutions "
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="500"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <img
            src={`/${props.info.frontmatter.solutions.image.image}`}
            alt={props.info.frontmatter.solutions.image.alt}
            className="img-fluid "
          />
          {/* <Img
        fluid={props.solutions.childImageSharp.fluid}
        className="solutions-img img-fluid"
      /> */}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center text-white col-12 col-md-6 pl-md-5">
        <div
          className="Solutions "
          data-sal="slide-left"
          data-sal-duration="1500"
          data-sal-delay="750"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <h2 className="text-uppercase mt-5 mt-md-1 mt-lg-1">
            {props.info.frontmatter.solutions.title}
          </h2>
        </div>
        <div
          className="Solutions "
          data-sal="slide-left"
          data-sal-duration="2000"
          data-sal-delay="1000"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <p className="mt-2 text-left">
            {props.info.frontmatter.solutions.desc}
            <ul type="none" className="mt-3 pl-0">
              <li>{props.info.frontmatter.solutions.sol1}</li>
              <li>{props.info.frontmatter.solutions.sol2}</li>
              <li>{props.info.frontmatter.solutions.sol3}</li>
              <li
                className={`${props.info.frontmatter.slug}-extra pt-3 d-none`}
              >
                {" "}
                <h3>TOTAL SOLUBILITY</h3>
              </li>
              <ul
                type="disc"
                className={`${props.info.frontmatter.slug}-extra pl-3 d-none`}
              >
                <li>CaMg(CO3)2: ≈ 190 kg/m3 (1.6 lb/gal)</li>
                <li>CaCO3: ≈ 216 kg/m3 (1.8 lb/gal)</li>
                <li>FeS: ≈ 170 kg/m3 (1.4 lb/gal)</li>
              </ul>
            </ul>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Solutions
