import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"
const Risks = ({ props }) => {
  return (
    <div
      className="Risks"
      data-sal="slide-right"
      data-sal-duration="2000"
      data-sal-delay="1000"
      data-sal-easing="ease"
      data-sal-once="false"
    >
      <div className="row mt-5 pb-5">
        <div className="d-flex flex-column justify-content-center text-white col-12 col-md-6 py-lg-5">
          <div className={`${props.info.frontmatter.slug}-risk1 `}>
            <p className="text-uppercase mb-lg-5 mt-2">
              {props.info.frontmatter.risk.description}
            </p>
            <small className="riskNote">
              {props.info.frontmatter.risk.note}
            </small>
          </div>
          <div className={`${props.info.frontmatter.slug}-risk2 d-none`}>
            <small className="text-uppercase mb-lg-5 mt-2">
              {props.info.frontmatter.risk.description}
            </small>
            <p className="riskNote mt-lg-5">
              {props.info.frontmatter.risk.note}.
            </p>
          </div>

          <a
            href="../contact"
            type="button"
            className=" btn btn-sm rounded btn-outline-light p-0 text-uppercase my-4 form-button"
          >
            Get In Touch
          </a>
        </div>
        <div className="d-flex flex-column col-md-6">
          <div className="third ">
            <img
              src={`/${props.info.frontmatter.risk.image.image}`}
              alt={props.info.frontmatter.risk.image.alt}
              className="risks-img-1 img-fluid"
              height="200"
            />
            {/* <Img
          fluid={props.solutions.childImageSharp.fluid}
          className="solutions-img img-fluid"
        /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Risks
