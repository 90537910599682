import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"

const Certificate = ({ props }) => {
  return (
    <div className="row mt-5 pb-5">
      <div className="d-flex flex-column col-md-6">
        <div
          className="properties"
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="500"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <div className="fourth">
            <img
              src={`/${props.info.frontmatter.physicalproperties.image.image}`}
              alt={props.info.frontmatter.physicalproperties.image.alt}
              className=" img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center text-white col-12 col-md-6 py-lg-5">
        <p className="d-flex flex-column my-auto">
          <ul type="">
            <div
              className="properties"
              data-sal="slide-left"
              data-sal-duration="1500"
              data-sal-delay="750"
              data-sal-easing="ease"
              data-sal-once="false"
            >
              <li type="none">
                <h4 className="fontChange text-uppercase mt-3">
                  PHYSICAL PROPERTIES
                </h4>
              </li>
            </div>
            <div
              className="properties pl-3"
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="1000"
              data-sal-easing="ease"
              data-sal-once="false"
            >
              <li>{props.info.frontmatter.physicalproperties.b1}</li>
              <br />
              <li>{props.info.frontmatter.physicalproperties.b2}</li>
              <br />
              <li>{props.info.frontmatter.physicalproperties.b3}</li>
              <br />
              <li>{props.info.frontmatter.physicalproperties.b4}</li>
              <br />
              {props.info.frontmatter.physicalproperties.b5 !== null && (
                <>
                  <li>{props.info.frontmatter.physicalproperties.b5}</li>
                  <br />
                </>
              )}

              {props.info.frontmatter.physicalproperties.b6 !== null && (
                <>
                  <li>{props.info.frontmatter.physicalproperties.b6}</li>
                  <br />
                </>
              )}

              {props.info.frontmatter.physicalproperties.b7 !== null && (
                <>
                  <li>{props.info.frontmatter.physicalproperties.b7}</li>
                  <br />
                </>
              )}

              {props.info.frontmatter.physicalproperties.b8 !== null && (
                <>
                  <li>{props.info.frontmatter.physicalproperties.b8}</li>
                  <br />
                </>
              )}

              {props.info.frontmatter.physicalproperties.b9 !== null && (
                <>
                  <li>{props.info.frontmatter.physicalproperties.b9}</li>
                  <br />
                </>
              )}
            </div>
          </ul>
        </p>
      </div>
    </div>
  )
}

export default Certificate
