import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"
const Benefits = ({ props }) => {
  return (
    <div>
      <div className="mobile-benefits">
        {" "}
        <div className="row">
          <div
            className="fluid-logo w-30 col-4 col-md-4"
            data-sal="zoom-out"
            data-sal-duration="2000"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-once="false"
            data-sal-repeat
          >
            <Img fluid={props.second.childImageSharp.fluid} />
          </div>

          <div
            className="col col-md-8 "
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-once="false"
          >
            <img
              src={`/${props.info.frontmatter.applications.image.image}`}
              alt={props.info.frontmatter.applications.image.alt}
              className="mt-2 img-fluid"
            />
            <ul
              type=""
              className="pl-0 mt-1"
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-once="false"
              data-sal-repeat
            >
              <li type="none">
                <h4 className="fontChange text-uppercase mt-3">
                  {props.info.frontmatter.applications.title}
                </h4>
              </li>
              <div className="pl-3">
                {props.info.frontmatter.applications.b1 !== null && (
                  <>
                    {" "}
                    <li>{props.info.frontmatter.applications.b1}</li>
                    <br />
                  </>
                )}
                {props.info.frontmatter.applications.b2.b2main !== null && (
                  <>
                    <li className="p-0">
                      {props.info.frontmatter.applications.b2.b2main}
                    </li>
                    <br />
                  </>
                )}

                {props.info.frontmatter.applications.b2.sub5 !== null && (
                  <li type="none">
                    <ul type="" className="subpoints">
                      {props.info.frontmatter.applications.b2.sub1 !== null && (
                        <>
                          <li>{props.info.frontmatter.applications.b2.sub1}</li>
                          <br />
                        </>
                      )}

                      {props.info.frontmatter.applications.b2.sub2 !== null && (
                        <>
                          <li>{props.info.frontmatter.applications.b2.sub2}</li>
                          <br />
                        </>
                      )}

                      {props.info.frontmatter.applications.b2.sub3 !== null && (
                        <>
                          <li>{props.info.frontmatter.applications.b2.sub3}</li>
                          <br />
                        </>
                      )}

                      {props.info.frontmatter.applications.b2.sub4 !== null && (
                        <>
                          <li>{props.info.frontmatter.applications.b2.sub4}</li>
                          <br />
                        </>
                      )}

                      {props.info.frontmatter.applications.b2.sub5 !== null && (
                        <>
                          <li>{props.info.frontmatter.applications.b2.sub5}</li>
                          <br />
                        </>
                      )}
                    </ul>
                  </li>
                )}
                {props.info.frontmatter.applications.b3 !== null && (
                  <>
                    <li>{props.info.frontmatter.applications.b3}</li>
                    <br />
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
        <div className="row">
          <div
            className="col col-md-10 justify-content-md-center"
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-delay="500"
            data-sal-easing="ease"
            data-sal-once="false"
            data-sal-repeat
          >
            {" "}
            <img
              src={`/${props.info.frontmatter.operationalbenefits.image.image}`}
              alt={props.info.frontmatter.operationalbenefits.image.alt}
              className="mt-3 img-fluid"
            />
            <ul type="" className="pl-0">
              <li type="none">
                <div
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-delay="500"
                  data-sal-easing="ease"
                  data-sal-once="false"
                  data-sal-repeat
                >
                  <h4 className="fontChange text-uppercase mt-2">
                    {props.info.frontmatter.operationalbenefits.title}
                  </h4>
                </div>
              </li>
              <div
                className="pl-3"
                data-sal="slide-left"
                data-sal-duration="2000"
                data-sal-delay="1000"
                data-sal-easing="ease"
                data-sal-once="false"
                data-sal-repeat
              >
                <li>{props.info.frontmatter.operationalbenefits.b1}</li>
                <br />
                <li>{props.info.frontmatter.operationalbenefits.b2}</li>
                <br />
                {props.info.frontmatter.operationalbenefits.subpoints.s1 !==
                  null && (
                  <ul type="" className="">
                    {props.info.frontmatter.operationalbenefits.subpoints.s1 !==
                      null && (
                      <>
                        <li>
                          {
                            props.info.frontmatter.operationalbenefits.subpoints
                              .s1
                          }
                        </li>
                        <br />
                      </>
                    )}

                    {props.info.frontmatter.operationalbenefits.subpoints.s2 !==
                      null && (
                      <>
                        <li>
                          {
                            props.info.frontmatter.operationalbenefits.subpoints
                              .s2
                          }
                        </li>
                        <br />
                      </>
                    )}

                    {props.info.frontmatter.operationalbenefits.subpoints.s3 !==
                      null && (
                      <>
                        <li>
                          {
                            props.info.frontmatter.operationalbenefits.subpoints
                              .s3
                          }
                        </li>
                        <br />
                      </>
                    )}

                    {props.info.frontmatter.operationalbenefits.subpoints.s4 !==
                      null && (
                      <>
                        <li>
                          {
                            props.info.frontmatter.operationalbenefits.subpoints
                              .s4
                          }
                        </li>
                        <br />
                      </>
                    )}
                  </ul>
                )}
                <li>{props.info.frontmatter.operationalbenefits.b3}</li>
                <br />
                <li>{props.info.frontmatter.operationalbenefits.b4}</li>
                <br />
                {props.info.frontmatter.operationalbenefits.b5 !== null && (
                  <>
                    <li>{props.info.frontmatter.operationalbenefits.b5}</li>
                    <br />
                  </>
                )}

                {props.info.frontmatter.operationalbenefits.b6 !== null && (
                  <>
                    <li>{props.info.frontmatter.operationalbenefits.b6}</li>
                    <br />
                  </>
                )}
                {props.info.frontmatter.operationalbenefits.b7 !== null && (
                  <>
                    <li>{props.info.frontmatter.operationalbenefits.b7}</li>
                    <br />
                  </>
                )}

                {props.info.frontmatter.operationalbenefits.b8 !== null && (
                  <>
                    <li>{props.info.frontmatter.operationalbenefits.b8}</li>
                    <br />
                    <small
                      className={`${props.info.frontmatter.slug}-benefits-note-2 d-none`}
                    >
                      * Do not let on dry surface
                    </small>
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits
