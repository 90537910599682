import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"
import video from "../../img/hold-cleaner.mp4"

const Introduction = ({ props }) => {
  return (
    <div className="d-flex flex-wrap my-5 pt-5">
      <div
        className="d-flex flex-column justify-content-xl-end col-md-5"
        data-sal="slide-right"
        data-sal-duration="1500"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        <img
          src={`/${props.info.frontmatter.image.image}`}
          alt={props.info.frontmatter.image.alt}
          className={`${props.info.frontmatter.slug}-img product-img1 img-fluid `}
        />
        <div
          className={`${props.info.frontmatter.slug}-video d-none embed-responsive embed-responsive-16by9 `}
        >
          <video controls muted autoPlay className="videos">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* <Img
      fluid={data.intro.childImageSharp.fluid}
      className="product-img1 img-fluid"
    /> */}
      </div>
      <div className="d-flex flex-column justify-content-end text-white col-11 col-lg-4 col-xl-5 ml-lg-2">
        <div
          className="wrapper"
          data-sal="slide-up"
          data-sal-duration="1800"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <Img fixed={props.logo.childImageSharp.fixed} className="mb-2 mt-3" />
        </div>
        <div
          className="wrapper"
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-once="false"
        >
          <h1 className="text-uppercase w-lg-50">
            {props.info.frontmatter.title}
          </h1>
          <h3 className="text-uppercase">{props.info.frontmatter.heading2}</h3>
          <div
            data-sal="slide-left"
            data-sal-duration="2000"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <p className="mt-3 intro">{props.info.frontmatter.description1}</p>
            <p className="mt-3 intro">{props.info.frontmatter.description2}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Introduction
