import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import "../components/styles.css"
import Navbar from "../components/Navbar"
import Sidemenu from "../components/Sidemenu"
import Table from "../components/products/Table"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import Certificate from "../components/products/Certificate"
import Properties from "../components/products/Properties"
import Introduction from "../components/products/Introduction"
import Benefits from "../components/products/Benefits"
import MobileBenefits from "../components/products/MobileBenefits"
import Solutions from "../components/products/Solutions"
import Risks from "../components/products/Risks"

const productTemplate = ({ data }) => {
  return (
    <>
      <SEO title={data.info.frontmatter.title} />
      <section className="container-fluid products position-relative">
        <div className="Hide">
          <Sidemenu />
          <div className="container text-white">
            <Navbar />
            <Introduction props={data} />

            <Benefits props={data} />
            <MobileBenefits props={data} />
            <Solutions props={data} />

            <Risks props={data} />

            <Properties props={data} />
          </div>

          {/* End of Container */}
          <div
            className={`${data.info.frontmatter.slug}-table d-none`}
            data-sal="zoom-out"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-once="false"
          >
            {" "}
            <div className={data.info.frontmatter.slug}>
              <Table />
            </div>
          </div>

          <div className="container">
            <p className="text-md-center text-white mt-md-5">
              <small className="riskNote">{data.info.frontmatter.note}</small>
            </p>
          </div>

          <Certificate props={data} />

          <div className="py-lg-3 ">
            {" "}
            <Footer />
          </div>
        </div>
      </section>
    </>
  )
}
export const query = graphql`
  query Products($slug: String) {
    info: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        series
        heading1
        description1
        heading2
        description2
        image {
          image
          alt
        }
        solutions {
          title
          desc
          sol1
          sol2
          sol3
          image {
            image
            alt
          }
        }
        risk {
          description
          note
          image {
            image
            alt
          }
        }
        applications {
          title
          b1
          b2 {
            b2main
            sub1
            sub2
            sub3
            sub4
            sub5
          }
          b4
          b3
          b5
          b6
          image {
            image
            alt
          }
        }
        operationalbenefits {
          title
          b1
          b2
          b3
          b4
          b5
          b6
          b7
          b8
          image {
            image
            alt
          }
          subpoints {
            main
            s1
            s2
            s3
            s4
            s5
          }
        }
        physicalproperties {
          b1
          b2
          b3
          b4
          b5
          b6
          b7
          b8
          b9
          title
          image {
            image
            alt
          }
        }
        note
        certificate {
          title
          description
        }
      }
    }
    logo: file(relativePath: { eq: "fluid-logo.png" }) {
      childImageSharp {
        fixed(width: 83, height: 28) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    intro: file(relativePath: { eq: "Bilge-main.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solutions: file(relativePath: { eq: "bilge_high_foam.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    risk: file(relativePath: { eq: "images-risk.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificate: file(relativePath: { eq: "certificate.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hsr: file(relativePath: { eq: "Bilge-applications.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    operational: file(relativePath: { eq: "benefits.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    physical: file(relativePath: { eq: "HCL.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "fluid.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    third: file(relativePath: { eq: "Fluid-large.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default productTemplate
