import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../styles.css"

const Certificate = ({ props }) => {
  return (
    <div className="container">
      <div className="row mt-0 py-5 d-flex justify-content-md-between">
        <div className="d-flex flex-column justify-content-center text-white col-12 col-md-5 pr-lg-5">
          <div className="wrapper">
            <div
              className=""
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-once="false"
            >
              <h2 className="text-uppercase mb-4">
                {props.info.frontmatter.certificate.title}
              </h2>
            </div>
            <div
              className=""
              data-sal="slide-right"
              data-sal-duration="2000"
              data-sal-delay="1000"
              data-sal-easing="ease"
              data-sal-once="false"
            >
              <p>{props.info.frontmatter.certificate.description}</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column  col-md-6">
          <div
            className="certificate "
            data-sal="slide-left"
            data-sal-duration="2000"
            data-sal-delay="1000"
            data-sal-easing="ease"
            data-sal-once="false"
          >
            <Img fluid={props.certificate.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Certificate
